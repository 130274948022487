/* html{
    overflow: hidden;
} */

body{
    overflow-x: hidden;
    overflow-y: auto !important;
}

.MuiDrawer-root .MuiTypography-root{
    color: black !important;
    background:transparent !important;
}

.selhov{
    background-color: white !important;
    color: black !important;
}

.selhov:hover{
    background-color: #333 !important;
    color: white !important;
}

.lihov{
    color: white;
    background-color: grey;
}

.lihov:hover{
    color: white !important;
    background-color: #333 !important;
}

.divIdToPrintDesktop{
    flex: 1;
    height: 100vh;
    display: flex;
    background-color: #fff;
    flex-direction: row;
    background-repeat: no-repeat;
    background-size: 180vh;
}

@media only screen and (min-width:992px){
    .SearchBoxItemDesktop{
        position: absolute !important;
        margin-top: 10% !important;
        z-index: 1000 !important;
    }
}

@media only screen and (min-width:300px) and (max-width:450px){
    .MuiContainer-maxWidthLg{
        display: block !important;
    }
    .iimglogg{
        width: 25%;
    }
    body{
        overflow-x: hidden;
        overflow-y: scroll !important;
        /* overflow-y: hidden !important; */
    }
    .divIdToPrintMobile{
        flex: 1;
        height: 120vh;
        display: flex;
        background-color: #333;
        flex-direction: row;
        /* background-image: url("./q_img.png"); */
    }
    .divTopTenMobile{
        margin-left: 20%;
        margin-top: 50%;
        border-radius: 3px;
        /* position: absolute; */
        /* left: 150px; */
        display: flex;
        height: 42vh;
        background-color: #bdbebf;
        width: 90vw;
        flex-direction: column;
    }
    .MuiDialog-paperScrollPaper{
        width: 100% !important;
    }
    .divOpenCredMobile{
        display: flex;
        flex-direction: row;
        /* padding: 50px; */
    }
    .txtEmailMobile{
        margin: 5% 5% 0% 5% !important;
        width: 90% !important;
    }
    .btnEmailMobile{
        margin: 5% 5% 5% 5% !important;
        width: 90% !important;
    }
    .modalQuizMobile{
        height: 90vh;
        flex-direction: column;
        background-color: #fafafa;
        width: 100vw;
        overflow: scroll;
    }
    .submitQuestionMobile{
        width: 50% !important;
        margin-top: 15% !important;
    }
    .xHRlineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 200% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .xHRClineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 90% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .divSearchBoxMobile{
        display: flex;
        flex-direction: row;
        margin: 5% 2.5% 10% 2.5% !important;
        padding-left: 10%;
        padding-top: 50%;
        /* background-color:#fff; */
        /* justify-content: center; */
        /* flex-direction:column; */
        /* align-items: center; */
    }
}

@media only screen and (min-width:450px) and (max-width:800px){
    .MuiContainer-maxWidthLg{
        display: block !important;
    }
    .iimglogg{
        width: 20%;
    }
    body{
        overflow-x: hidden;
        overflow-y: scroll !important;
        /* overflow-y: hidden !important; */
    }
    .divIdToPrintMobile{
        flex: 1;
        height: 140vh;
        display: flex;
        background-color: #333;
        flex-direction: row;
        /* background-image: url("./q_img.png"); */
    }
    .divTopTenMobile{
        margin-left: 20%;
        margin-top: 40%;
        border-radius: 3px;
        /* position: absolute; */
        /* left: 150px; */
        display: flex;
        height: 42vh;
        background-color: #bdbebf;
        width: 90vw;
        flex-direction: column;
    }
    .MuiDialog-paperScrollPaper{
        width: 100% !important;
    }
    .divOpenCredMobile{
        display: flex;
        flex-direction: row;
        /* padding: 50px; */
    }
    .txtEmailMobile{
        margin: 5% 5% 0% 5% !important;
        width: 90% !important;
    }
    .btnEmailMobile{
        margin: 5% 5% 5% 5% !important;
        width: 90% !important;
    }
    .modalQuizMobile{
        height: 90vh;
        flex-direction: column;
        background-color: #fafafa;
        width: 100vw;
        overflow: scroll;
    }
    .submitQuestionMobile{
        width: 50% !important;
        margin-top: 15% !important;
    }
    .xHRlineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 200% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .xHRClineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 90% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
}

@media only screen and (min-width:768px) and (max-width:992px){
    .MuiContainer-maxWidthLg{
        display: block !important;
    }
    .iimglogg{
        width: 15%;
    }
    body{
        overflow-x: hidden;
        overflow-y: scroll !important;
        /* overflow-y: hidden !important; */
    }
    .divIdToPrintMobile{
        flex: 1;
        height: 140vh;
        display: flex;
        background-color: #333;
        flex-direction: row;
        /* background-image: url("./q_img.png"); */
    }
    .divTopTenMobile{
        margin-left: 20%;
        margin-top: 40%;
        border-radius: 3px;
        /* position: absolute; */
        /* left: 150px; */
        display: flex;
        height: 42vh;
        background-color: #bdbebf;
        width: 90vw;
        flex-direction: column;
    }
    .MuiDialog-paperScrollPaper{
        width: 100% !important;
    }
    .divOpenCredMobile{
        display: flex;
        flex-direction: row;
        /* padding: 50px; */
    }
    .txtEmailMobile{
        margin: 5% 5% 0% 5% !important;
        width: 90% !important;
    }
    .btnEmailMobile{
        margin: 5% 5% 5% 5% !important;
        width: 90% !important;
    }
    .modalQuizMobile{
        height: 90vh;
        flex-direction: column;
        background-color: #fafafa;
        width: 100vw;
        overflow: scroll;
    }
    .submitQuestionMobile{
        width: 50% !important;
        margin-top: 15% !important;
    }
    .xHRlineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 200% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
    .xHRClineMobile{
        background-color: #34495e !important;
        height: 0.2vh !important;
        width: 90% !important;
        text-align: center !important;
        margin: 2% 5% 2% 6% !important;
    }
}

::-webkit-scrollbar {
    width: 2%;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4169e1; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
    /* cursor: pointer !important; */
  }

.logoDivDesktop{
    /* width: '20vw'; */
    flex: 4;
    background-color: #ededed;
    display: flex;
    justify-content: center;
    padding: 10%;
    /* alignItems: 'center'; */
}

.logoDivMobile{
    /* width: '20vw'; */
    flex: 4;
    /* backgroundColor: '#eeeeee'; */
    display: flex;
    justify-content: center;
    padding: 1%;
    /* alignItems: 'center'; */
}

.logoDivImgDesktop{
    margin-left: 10%;
    margin-top: 5%;
    width:95%;
    height:8%;
}

.logoDivImgMobile{
    margin-top: 25%;
    width:90px !important;
    height:30px;
}

.anchorlogoDivImgMobile{
    /* margin-left: 23%; */
    float: right;
}

.rightAreaDesktop{
    /* width: '90vw'; */
    flex: 10;
    display: flex;
    /* justify-content: 'center', */
    flex-direction: column;
    align-items: center;
}

.rightAreaMobile{
    /* width: '90vw'; */
    flex: 10;
    display: flex;
    /* justify-content: 'center', */
    flex-direction: column;
    align-items: center;
}

.right-nav{
    display: flex;
    justify-content: end;
    align-items: center;
}

.rightHeaderDesktop{
    width: 100vw;
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.rightfooterDesktop{
    height: 7vh;
    width: 100vw;
    background-color: #333;
    display: flex;
    
    flex-direction: row;
    align-items: flex-end;
    position: fixed;
    bottom: -0px;
}
.rightfooterDesktop_contact{
    height: 7vh;
    width: 100vw;
    background-color: #333;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
    position: fixed;
    bottom: -0px;
}

.rightHeaderMobile{
    /* display: none !important; */
    margin-left: -4%;
    margin-top: 20%;
    position: absolute;
    background-color: #333;
    /* background:rgba(0,0,0,0); */
    opacity: 1;
    z-index: 1000;
    height: 100vh;
    /* border:1px solid white; */
    width: 60vw;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}


.tblHeadMobile{
    padding: 5%;
    width: 100%;
    position: absolute;
    background-color: #333;
    color: white !important;
}

.tblHeadMobile h5{
    width: 100%;
    padding: 4%;
    /* background-color:#3498db ; */
    /* border:1px solid rgba(0,0,0,0.6) !important; */
}

.btactive:hover{
    /* background-color:#2ecc71 ; */
    background-color:#2ecc71 !important;
}

.btactive{
    background-color:#3498db !important;
}
h5.btactive{margin:0; border-bottom: 1px solid #fff;}

.fa-bars{
    width: 100% !important;
}

.fa-times{
    display: none;
    padding-left: 18% !important;
    /* margin-right: 10% !important; */
}

.homeButtonDesktop{
    font-weight: bold;
    cursor: pointer;
    margin-left: 4%; 
    font-size: 100%; 
    display: flex; 
    font-family: Arial, Helvetica, sans-serif; 
    color: #fff;
}

.homeButtonMobile{
    font-weight: bold;
    cursor: pointer;
    margin-left: 4%; 
    font-size: 100%; 
    display: flex; 
    font-family: Arial, Helvetica, sans-serif; 
    color: #fff;
}

.subjectiveCSS{
    position: absolute !important;
    margin-top: -8% !important;
    margin-left: -40% !important;
}

.subjectiveCSS button{
    margin-top: -3% !important;
}

.homeButtonDesktop:hover{
    color: #74b9ff;
}

.accordion {
    outline: none !important;
    padding:2% 2% 0% 2% !important;
    border-radius: 3pt !important;
    margin: 0% 0.2% 0.2% 0%;
}

.accordion__icon{
    margin-top: -2% !important;
}

.accordion__text{
    padding:1% 2% 1% 2% !important;
}

.accordion__title, .accordion__content{
    text-align: left !important;
}

.accordions{
    max-height: 30vh !important;
    overflow-y: scroll !important;
    position: absolute !important;
    width: 50vw !important;
    margin-left: -30% !important;
    margin-top: 22% !important;
}

.homeButtonMobile:hover{
    color: #74b9ff;
}

.dashButtonDesktop{
    font-weight: 600;
    cursor: pointer;
    font-size: 100%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #575757;
    align-items: center;
    padding: 5px 20px;
    border-left: 1px solid rgba(0,0,0,.15);
    
    position: relative;
    height: 30px;
}
.dashButtonDesktop:first-child {
    border-left: none;
}
.dashButtonDesktop.dropdown i {
    margin: 0 0 0 10px;
    font-size: 32px;
}
.dropdown-menu {
    right: 20px;
    left: auto;
    top: 99%;
    text-align: right;    
}
.dashButtonDesktop.dropdown:hover .dropdown-menu {
    display: block;
}
.dropdown-menu .set {
    display: block;
    padding: 10px;
    border-bottom: 1px solid rgba(0,0,0,.15);
}
.dropdown-menu .set:last-child {
    border-bottom: none;
}

.dashButtonMobile{
    font-weight: bold;
    cursor: pointer;
    margin-left: 3%;
    font-size: 100%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}

.dashButtonDesktop:hover{
    color: #74b9ff;
}

.dashButtonMobile:hover{
    color: #74b9ff;
}

.divQubitImgDesktopOuter {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 0;
}
.divQubitImgDesktop{
    padding-bottom: 30px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
}

.divQubitImgMobile{
    height: 20vh;
    background-color: #333;
    justify-content: center;
    /* flex-direction:'column'; */
    padding-left: 20%;
    padding-top: 10%;
    align-items: center;
}

.QubitImgDesktop{
    width:160px;
    height:55px;
}

.QubitImgMobile{
    margin-left: 21%;
    width:130px;
    height:45px;
}

.QubitHeadlineDesktop{
    color: #fff;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 'bold';
    font-size: 110%;
    margin-top: 3%;
}

.QubitHeadlineMobile{
    color: #fff;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 'bold';
    font-size: 150%;
    margin-top: 10%;
}

.QubitParaDesktop{
    text-align: center;
 
    font-size: 90%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
    width: 80%;
    font-weight: normal;
    font-size: 14px;
}

.QubitParaMobile{
    position: absolute !important;
    margin-top: 40%;
    padding: 3%;
    text-align: center;
    font-size: 110%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color:white;
}

.divSearchBoxDesktop{
    display: flex;
    flex-direction: row;
    margin: 2.5% auto 10% auto;
   /* padding-left: 1%;*/
    /* background-color:#fff; */
    /* justify-content: center; */
    /* flex-direction:column; */
    /* align-items: center; */
}

.divSearchBoxMobile{
    display: flex;
    flex-direction: row;
    margin: 2.5% 2.5% 10% 2.5%;
    padding-left: 10%;
    padding-top: 50%;
    /* background-color:#fff; */
    /* justify-content: center; */
    /* flex-direction:column; */
    /* align-items: center; */
}

.SearchBoxDesktop{
    font-weight: bold;
    position: absolute;
    /* margin:5px; */
    margin-left: -30%;
    margin-top:0.3%;
    justify-content:flex-end;
    font-size: 90%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 29%;
}

.SearchBoxMobile{
    font-weight: bold;
    position: absolute;
    /* margin:5px; */
    margin-left: -35%;
    margin-top:3%;
    justify-content:flex-end;
    font-size: 100%;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 75%;
}

.divSearchBarDesktop{
    display: flex;
    position: relative;
    /* left: 230px; */
    z-index: 1000;
    flex-direction: column;
}

.divSearchBarMobile{
    margin-left: 20%;
    display: flex;
    position: absolute;
    z-index: 999;
    flex-direction: column;
}

.SearchBarDesktop{
    padding-left: 1.5%;
    background-color: #f7f7f9;
    height: 6.6vh;
border:1px solid #ccc;
    border-radius: 5px;
    font-size: 18px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 500px;
     /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
    
     box-shadow: 3px 3px 3px #bfbfbf; 
}

.SearchBarMobile{
    padding-left: 5px;
    background-color: #8e8f91;
    height: 30px;
    border-radius: 3px;
    font-size: 14px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 45%;
    margin-top: 7%;
    margin-left: -19%;
}

.SearchBoxItemDesktop{
    background-color: #f7f7f9;   
    width: 500px;   
    box-shadow: 3px 3px 3px #bfbfbf;
    
}

.SearchBoxItemMobile{
    background-color: #fff;
}

.inputSearchDesktop{
    width: 500px !important;
}

.inputSearchMobile{
    width: 600px !important;
}

.inputSearchDesktop input{
    font-size: 92%;
    padding-left: 0%;
    padding-right: 2%;
}

.inputSearchMobile input{
    font-size: 95%;
    padding-left: 0%;
    padding-right: 2%;
}

.divSearchBarMobile span+span{
    width: 37.8% !important;
    margin-left: 1% !important;
}

.selcourse input{
    text-align: center !important;
}

.numques input{
    text-align: center !important;
}

.listSearchDesktop{
    cursor: pointer;
    border: 1px solid #eee;
    margin: 0px;
    padding: 4px;
    font-size: 13px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
}

.listSearchMobile{
    cursor: pointer;
    /* border: 1px solid #eeeeee; */
    width: 119%;
    margin-left: -18.9%;
    padding: 4px;
    font-size: 12px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
}

.listSearchDesktop:hover{
    color:#eeeeee;
    background-color: #333;
}

.listSearchMobile{
    color:#333;
    background-color: white;
}

.listSearchMobile:hover{
    color:#eeeeee;
    background-color: #333;
}

.divSelectNumberDesktop{
    display: flex;
    margin: 10px auto;
    /* margin-left: 100px; */
}

.divSelectNumberMobile{
    z-index: -1;
    position: absolute;
    display: flex;
    margin-top: 12%;
    margin-left: 16%;
}

.SelectNumberDesktop{
    margin-top: 2%;
    font-size: 16px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
    opacity: 0.7;
}

.SelectNumberDesktop_tq{
    margin-top: 12px;
    /*position: absolute;*/
    left: -35px;
    font-size: 16px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
    opacity: 0.7;
}

.SelectNumberMobile{
    margin-top: 8%;
    margin-left: -40%;
    font-size: 14px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}

.inputNumberDesktop{
    margin-top: 8px;
    margin-right: 5px;
    padding-left: 5px;
    justify-content: flex-start;
    background-color: #eeeeee;
    height: 25px;
    border-right: 40px;
    font-size: 16px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 55px;
}

.divSelectNumberMobile .inputNumberMobile{
    margin-top: 16%;
    margin-right: 5px;
    padding-left: 5px;
    margin-left: -30% !important;
    width: 10% !important;
    justify-content: flex-start;
    background-color: #fff;
    height: 25px;
    border-right: 3px;
    font-size: 10px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: 30px;
}

.inputNumDesktop{
    width: 600;
}

.inputNumMobile{
    width: 600;
}

.inputNumDesktop input{
    border-radius: 5px;
    font-size: 16px;
    padding: 1%;
    font-weight: bold;
}

.divSelectNumberMobile .inputNumMobile input{
    font-size: 100%;
    padding: 1%;
    font-weight: bold;
}

.divGoButtonDesktop:hover{
    cursor: pointer;
}

.divSelectNumberMobile .divGoButtonMobile{
    margin-top: 14.5%;
}

.divSelectNumberMobile .divGoButtonMobile:hover{
    cursor: pointer;
}

.divTopTenDesktop{
    margin-left: 100px;
    margin-top: -1.5%;
    border-radius: 3px;
    position: relative;
    left: 150px;
    display: flex;
    height: 44vh;
    background-color: #bdbebf;
    width: 35vw;
    flex-direction: column;
}

.TopTenDesktop{
    margin: 3% 0% 2% 3%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
}

.TopTenMobile{
    margin: 3% 0% 2% 3%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
}

.listTopTenDesktop{
    /* width: 100vh; */
    margin: 0px;
    padding: 1% 0% 0% 2%;
    font-size: 12px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
}

.listTopTenMobile{
    padding: 1.5% 0% 0% 2%;
    font-size: 12px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
}

.listTopTenDesktop:hover{
    color:#34495e;    
    cursor: pointer;
    font-size: 12.5px;
}

.listTopTenMobile:hover{
    color:#34495e;
    cursor: pointer;
    font-size: 12.5px;
}

.divLastDesktop{
    /* width: 60; */
    flex: 2;
    display: flex;
    /* justifyContent: 'center'; */
    background-color: #333;
}

.divLastMobile{
    /* width: 60; */
    flex: 2;
    display: flex;
    /* justifyContent: 'center'; */
    background-color: #333;
}

.navbarMobile{
    height:8.5vh;
    /* padding-bottom: 20%; */
    background-color:#f3f3f3;
}

.divmainMobile{
    background-color:#333 !important;
}




.contbtn{ padding: 5px 10px;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer; border-radius: 5px;
    font-size: 14px; background-color: #007bff;
    display: inline;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff; 
}
.contbtn h3 {display: inline;font-size: 14px; margin: 0 auto 0 auto;}
.contbtn img {width: auto; height: auto; margin-left: 10px;}
.size12{ font-size: 15px; color: #333;margin-top: 12px;}
.size13{ font-size: 15px; color: #333;margin-top: 12px;}
.rightfix{position: fixed; right: 40px; z-index: 10;}

.contact-container {
    position: relative;
    background: transparent;
    width: calc(100% - 120px);
    height: 100vh;
    margin: 0 auto;
}
.contact-container::before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff url(image/wa.jpg) center;
    opacity: 0.4;
}

 .cursor-pointer{
     cursor: pointer;
 }

.contactForm {
    position: absolute;
    width: 35%;
    top: calc(50% - -200px);
    left: 0;
    z-index: 100;
    background: #236380;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
}
.contactForm .ui.form input,
.contactForm .ui.form input:not([type]),
.contactForm .ui.form textarea {
    background: #f0f0f0;
}

.contactForm h1 {
    color: #fff;
}
.address-section {
    padding: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    height: 70vh;
    top: 15vh;
    overflow: auto;
    position: absolute;
    right: 0;
    width: 70%;
    margin: 0 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    background: #236380;
}
.address-section .contact-content-map {
    min-width: 100%;
    max-width: 100%;
}
.address-section .contact-content-map .embed-responsive-21by9::before {
    padding-top: 32%;
}
.address-section .contact-content {
    min-width: 100%;
    max-width: 100%;
    padding: 15px 20px 15px 10%;
    position: relative;
    color: #fff;
}
.address-section .contact-content address {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
}
.address-section .contact-content h4 {
    font-size: 20px;
    padding-right: 40px;
    margin-bottom: 10px;
    color: #fff;
}
.address-section .contact-content strong {
    display: block;
}
.address-section .contact-content i.fa {
    position: absolute;
    width: 40px;
    height: 40px;
    color: #f00;
    background: #dadada;
    border-radius: 10px;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
.address-section > div:nth-child(odd) {
    border-left: none !important;
}
.address-section > .contact-content:first-child {
    border-top: none !important;
}
.address-section > div:nth-child(2) {
    border-top: none !important;
}
img {
    max-width: 100%;
}
input::placeholder,
textarea::placeholder {
    color: #797979 !important;
    opacity: 1; /* Firefox */
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #797979 !important;
}
  
input::-ms-input-placeholder,
textarea:-ms-input-placeholder { /* Microsoft Edge */
    color: #797979 !important;
}
.MuiCircularProgress-colorSecondary svg {
    color: #4169e1 !important;
}
.main-laoder {
    margin-top: 110px;
}

@media (max-width: 501px) {
    .SearchBarDesktop, .inputSearchDesktop, .SearchBoxItemDesktop {
        width: 100% !important;
    }
    .divSearchBarDesktop {
        padding: 0 10px;
    }
    .divSelectNumberDesktop {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.footer {
    position: fixed;
    bottom: 0;
    padding: 7px 15px;
    width: 100%;
    text-align: center;
    background: #4183c4;
}
.footer a {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.footer a:hover {
    color: #fff;
    text-decoration: underline;
}
.start-btn {
    cursor: pointer;
    background: #1aafd9;
    color: #fff;
    border-radius: 5px;
    height: 37px;
    align-items: center;
}
.start-btn:hover {
    background: #1488a8;
}
.use-timer .timer-section #spTimer {
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    margin-top: 10px;
    margin-right: -280px;
}

.use-timer .PrivateSwitchBase-root-24 {
    padding: 10px 0px 10px 9px;
}
.use-timer  #spTimerText {
    margin-top: 6px;
}
.timer-section {
    display: table;
    width: 100%;
    margin-bottom: -23px;
}
.generated-que-ans details {
    margin-bottom: 15px;
    border: 1px solid rgba(0,0,0,.05);
}
.generated-que-ans summary {
    background: rgba(0,0,0,.05);
    color: rgba(0,0,0,.95);
    font-weight: 600;
    display: flex;
    padding: 15px;
    align-items: flex-start;
}
.generated-que-ans summary::after {
    content: '+';
    min-width: 28px;
    min-height: 28px;
    max-width: 28px;
    max-height: 28px;
    line-height: 28px;
    margin-top: -5px;
    margin-left: auto;
    font-size: 26px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,.95);
    font-weight: 400;
}
.generated-que-ans summary > span {
    padding-right: 10px;
}
.generated-que-ans details[open] summary::after {
    content: '-';
}
.rightHeaderDesktop {
    border-bottom: 1px solid #0000005c;
    box-shadow: 0px -3px 13px 3px #0000005c;
    z-index: 9999;}
@media (max-width: 767px) {
    
    .start-btn {
        justify-content: center;
    }
    .rightHeaderDesktop {
        
        flex-direction: column;
        align-items: flex-end;
        border: 1px solid rgba(0,0,0,.15);
        padding-right: 0;
        border-bottom: 0;
    }
    .rightHeaderDesktop::after {
        content: '';
        border-top: 2px solid #000;
        width: 100%;
        max-width: 32px;
        top: 27px;
        margin: 0px 15px 0;
        text-align: right;
        display: block;
        position: absolute;
    }
    .rightHeaderDesktop::before {
        content: '';
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        width: 100%;
        max-width: 32px;
        margin: 15px;
        text-align: right;
        display: block;
        font-size: 16px;
        padding: 10px;
    }
    .dashButtonDesktop {
        border-right: 0;
        border-bottom: 1px solid rgba(0,0,0,.15);
        width: 100%;
        background: #fff;
        margin: 0;
        padding: 10px 15px;
        justify-content: flex-end;
        display: none !important;
        height: auto;
    }
    .rightHeaderDesktop:hover .dashButtonDesktop {
        display: flex !important;
    }
    .rightHeaderDesktop .dashButtonDesktop[style="display: none;"] {
        display: none !important;
    }
    .rightHeaderDesktop:hover .dashButtonDesktop [style="display: flex;"] {
        display: flex !important;
    }
    .contact-container {
        height: auto;
        min-height: 100vh;
        width: calc(100% - 30px);
    }
    .contactForm {
        position: relative;
        margin-top: 60px;
        top: 0;
        left: 0;
        width: 100%;
    }
    .address-section {
        width: 100%;
        position: relative;
        top: 0;
        margin: 30px 0;
        padding: 0;
        height: auto;
    }
    .address-section .contact-content {
        min-width: 100%;
        max-width: 100%;
        border-left: none;       
    }
    .address-section > div:nth-child(odd),
    .address-section > div:nth-child(2) {
        border-top: 1px solid #dadada !important;
    }
    .rightfix {
        top: 0;
    }
    .ui.responsivTable.table:not(.unstackable) thead.tabHead {
        display: none;
    }  
    .responsivTable .ribbon {
        display: none !important;
    }
    .responsivTable td {
        text-align: right !important;
    }
    .responsivTable td[mob-title]::before {
        content: attr(mob-title)' : ';
        float: left;
        padding-right: 10px;
    }
    .responsivTable td.btnTd {
        text-align: center !important;
    }
    .ui.responsivTable.table [class*="single line"], .ui.responsivTable.table[class*="single line"] {
        white-space: normal;
    }
    .use-timer .timer-section #spTimer {
        margin-right: 0;
        float: left;
        font-size: 14px;
        width: 100%;
    }
    .timer-section {
        margin-bottom: 0;
    }
    .use-timer #spTimerText {
        margin-top: 6px;
        width: 146px;
        display: inline-block;
    }
   
}

.no-break{
    white-space: nowrap;
}