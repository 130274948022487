.email-test {
    max-width: 600px;
    padding: 0px;
    margin: 0 auto !important;
    position: relative;
}
.email-test .MuiTextField-root {
    width: 100%;
}
.email-test .ui.blue.button {
    background: #1aafd9;
}
.email-test .form-side {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 30px;
}
.send-report-btn {
    position: absolute;
    bottom: 10px;
}
.send-report-btn2 {
    align-self: end;
    margin-bottom: -25px !important;
    position: relative;
    z-index: 1;
}
/* .my-test-outer {
    justify-content: center;
} */
.my-test-outer .test-table {
    width: 100%;
}
.text-warning {
    color: #ed9900 !important;
}
.student-test {
    flex-direction: column;
    background-color: #fafafa;
    align-items: center;
    height: 85vh;
}
.green.small.button.start-test {
    display: block;
    position: absolute;
    left: calc(50% - 88px);
    border: 4px solid #11872d;
    margin: 40px auto !important;
    font-size: 30px;
    line-height: 32px;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    text-shadow: 0 1px 1px #444;
    -moz-box-shadow: inset 8px 7px 28px rgba(255, 255, 255, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
    -webkit-box-shadow: inset 8px 7px 28px rgba(255, 255, 255, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
    box-shadow: inset 8px 7px 28px rgba(49, 49, 49, 0.4), inset -2px -2px 2px rgba(0, 0, 0, .4);
}

@media (max-width: 767px) {
    .email-test .image-side {
        display: none;
    }
    .email-test .form-side {
        padding: 30px 30px 130px;
    }
    .send-report-btn2 {
        margin: 0 auto 15px !important;
    }
    .student-test #MainContent {
        text-align: center;
    }
    .student-test .test-table thead {
        display: none !important;
    }
    .student-test .test-table td::before {
        content: attr(data-title);
    }
    .student-test .test-table td:nth-child(2):before, 
    .student-test .test-table td:nth-child(3):before {
        display: block;
    }
    .student-test .test-table td .ui.teal.button {
        float: right;
    }
}
