::-webkit-input-placeholder {
    color: rgba(230, 230, 230, 0.7) !important;
}

button.tabfix2{ background-color: #fff; border-bottom:1px solid #ccc;  }

button.tabfix2:hover{ border-bottom:1px solid  rgb(27, 1, 255);  }

button.tabfix2:focus{ border-bottom:1px solid  rgb(27, 1, 255); border: none;}

button > span + span{border: none;}
button:focus{border: none;}
.col-md-2 > .row > .col-md-12 > button.ui.button{ padding: 10px 10px;  margin-top: 15px;}
.col-md-2 > .row > .col-md-12 > button.ui.button + button{ margin-top: 10px;}
.sweetAlertWidth{
    width: 80% !important;
}


.banner {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1aafd9;
    width: 100%;
    margin-top: 25px;
}
.banner img {
    min-width: 100%;
    max-width: 100%;
}
.banner h1 {
    color: #fff;
}
.about-contant {
    font-size: 22px;
    line-height: 1.6;
    padding: 30px 0;
}
#getLink {
    background: rgba(0, 0, 0, 0.04);
    margin: 15px 0;
    padding: 10px 15px 8px;
    font-size: 16px;
    border-radius: 5px;
    word-break: break-word;
}
#getLink:empty {
    display: none;
}
.attempt-row {
    overflow: hidden;
}
.attempt-outer {
    width: calc(100% - 30px);
    margin: 10px 15px;
    float: left;
    box-sizing: border-box;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #1aafd9;
}
.attempt-outer:nth-child(even) {
    border-right: none;
}
.attampt-ans {
    padding: 0 0px;
    margin: 0;
}
.ques-table {
    font-weight: 500;
}
.attampt-ans .student-id {
    padding: 10px 10px 7px;
    border-bottom: 1px solid rgba(34,36,38,.1);
    color: #fff;
    background: #1aafd9;
    font-weight: bold;
}
.attampt-ans.text-success .student-id {background: #28a745}
.attampt-ans.text-warning .student-id {background: #ed9900}
.attampt-ans.text-danger .student-id {background: #dc3545}
.attempt-outer.text-success { border-color: #28a745;}
.attempt-outer.text-warning { border-color: #ed9900}
.attempt-outer.text-danger { border-color: #dc3545}
.attampt-ans .student-action .btn {
    border-radius: 0;
}

.attampt-ans .student-ans {
    padding: 40px 10px 10px;
    color: #212529;
    position: relative;
}
.attampt-ans .student-ans .scroll-ans {
    overflow: auto;
    max-height: 250px;
    margin: 0 -10px;
    padding: 0 10px;
}
.student-ans .btn {
    border-radius: 30px;
    padding: 2px 15px;
    margin: 10px 10px 0 0;
}
.student-ans .btn.btn-outline-warning {
    color: #d46c00;
    border-color: #d46c00;
}
.student-ans .btn.btn-outline-warning:hover {
    color: #212529;
}
.attampt-ans .student-ans::before {
    content: "Answer: ";
    display: inline-block;
    padding-right: 50px;
    border-bottom: 1px solid #212529;
    position: absolute;
    top: 10px;
    left: 10px;
}
.attampt-ans .student-action {
    padding: 0px;
    border-bottom: 1px solid rgba(34,36,38,.1);
}
table tr:last-child .attampt-ans .student-action {
    border-bottom: none;
}
.attampt-ans .ans-action-outer {
    display: flex;
    width: 100%;
}
.MuiDialog-paper {
    width: 100%;
}

@media (max-width: 767px) {
    .test-selections {
        padding: 0 15px;
    }
    .test-selections > .row > div[class*="col-md"] {
        margin-bottom: 20px;
    }
    #getLink {
        margin: 15px;
    }
    .add-question {
        margin-left: 0;
        margin-right: 0;
    }
    .ques-table thead {
        display: none !important;
    }
    .ques-table tbody td[dataTitle]::before {
        content: attr(dataTitle);
        display: block;
        padding: 5px 10px;
        background: #ccc;
        margin: 0 -10px 8px;
    }
    .ques-table.ui.table:not(.unstackable) tr {
        padding: 0;
    }
    .ques-table.ui.table tr td:first-child {
        background: #212529;
        color: #fff;
        padding: 4px 10px;
    }
    .ques-table.ui.table tr td:nth-child(2) {
        border-bottom: 1px solid #ccc;
        padding: 0px 10px 8px !important;
    }
    .ques-table.ui.table tr td:nth-child(3) {
        border-bottom: 1px solid #ccc;
        padding: 0px 10px 8px !important;
    }
    .ques-table.ui.table tr td:nth-child(3) .p-3 {
        padding: 0 !important;
    }
    
    .attampt-ans .student-action .btn {
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.text-bg {
    background: white;
    border: 1px solid lightgrey;
    box-shadow: 2px 2px 7px 0px lightgrey;
    padding: 22px;
}

.text {
    color: black;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: .5px;
    font-size: 14px;
}

.textLetButton{
    font-weight: 500;
    line-height: 24px;
    letter-spacing: .5px;
    font-size: 14px;
}

.text-Center{
    text-align:center;
}

.custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #28bae6;
    box-shadow: 0 20px 75px rgb(0 0 0 / 23%);
    color: #fff;
}

.custom-ui > button {
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
}

/* width */
::-webkit-scrollbar {
    width: 11px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4169e1; 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #213e93; 
  }