.main_body{
    flex: 1;
    height: "100vh";
    display: "flex";
    flex-direction: "column";
    background-color: "#eeeeee"
}

.MuiDrawer-root .MuiTypography-root{
    color: black !important;
    background:transparent !important;
}

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Animation for the spinner */
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  