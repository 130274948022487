@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body, .mont{
    font-family: 'Montserrat', sans-serif!important;
}

.w-100{
    width:100%;
}

.img-bg-card{
    background-color: #dde3ef;
    border-radius: 15px;
    padding: 8px 13px 0px 13px;
}

.text-box{
    text-align: center;
    margin-top: 16px;
}

.text-box h6{
    font-size: 12px;
}

.bg-grey{
    min-height: 100vh;
    background: #f9f9f9;
}

.main-card{
    background: white;
    box-shadow: 1px 0px 5px 2px #00000012;
    border: 1px solid #00000014;
    border-radius: 10px;
}