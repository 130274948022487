.safari_div {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.div1 {
    overflow: auto;
    height: 90%;
}

.div2 {
    overflow: hidden;
    width: 100%;
}

.test-outer {
    background-color: #fafafa;
}

.print-container {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    padding: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
}

.actions-btn {
    font-size: 20px;
}

.print-heading {
    background: royalblue;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    margin-bottom: 0px;
    position: relative;
}

.print-heading-attempted {
    background: #a4b5e7;
}

.print-heading a,
.print-heading a:hover,
.print-heading a:focus {
    color: #fff;
    cursor: pointer;
}

.go-back {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
}

.flip-x {
    transform: scaleX(-1);
}

.display-inline-block {
    display: inline-block;
}

.print-questions {
    background: #efefef;
    padding: 15px;
    font-size: 16px;
}

.print-questions span {
    display: block;
}

.print-questions img {
    max-width: 100%;
}
.ans-options{
    position: relative;
    top: 0px;
}
.ans-options>.option {
    font-size: 16px;
    align-items: flex-start;
    display: block;
}

.ans-options>span {
    display: block;
}

.ans-options>.option .ans-content {
    display: block;
    padding-top: 10px;
}

.ans-options>.option .ans-content img {
    display: block;
    margin: 6px 0;
    max-width: 100%;
}

.ans-radio {
    position: relative;
}

.ans-options a.text-primary:hover {
    color: #fff !important;
}

.header,
.states-heading {
    text-align: center;
    border-bottom: 1px solid #00000029;
    box-shadow: 8px 4px 12px -1px #00000033;
    margin-bottom: 15px;
}

.header>div:empty {
    display: none !important;
}

.header {
    background: white !important;
}

.top-sticky {
    position: sticky;
    top: 0;
    z-index: 10;
}

.text-center-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-body.q-instractions {
    border: 1px solid #ccc;
    display: block;
    padding: 0;
    border-bottom: none;
}

.q-instractions .heading {
    padding: 5px 15px;
    border-bottom: 1px solid #ccc;
}

.countdown {
    margin: 0 0 20px 0;
    padding: 10px 15px;
    text-align: center;
    border: none;
    border-bottom: 0;
    background: #0b445c;
    color: #fff;
    position: sticky;
    top: 105px;
    z-index: 10;
}

.countdown.top {
    position: fixed;
    top: 43px;
    z-index: 10;
    border: 0;
    width: 100%;
    left: 0;
}

.countdown span {
    color: #fff !important;
    justify-content: center;
}

.share-btn {
    position: relative;
    margin-right: 10px;
    background: #fff;
    color: #4169e1;
    padding: 4px 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
}

.like-btn {
    position: relative;
    margin-right: 10px;
    background: #fff;
    color: #4169e1;
    font-weight: normal;
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 14px;
}

.like-btn i {
    margin-right: 5px;
}

.like-btn input {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    left: 0;
    top: 0;
    cursor: pointer;
}

.like-btn input:checked+.fa-thumbs-o-up::before {
    content: "\f164";
    /* color: yellow;*/
}

.like-btn input:checked+.fa-thumbs-o-down::before {
    content: "\f165";
    /* color: yellow;*/
}

.print-heading .like-btn,
.print-heading .share-btn {
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
}

#divClapping {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    max-width: 412px;
    height: 78px;
    align-items: center;
    justify-content: center;
    z-index: 10;
    display: none;
    background: url(../src/image/clap.gif) repeat-x;
    background-size: 147px;
}

.states-heading {
    color: #fff;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
}

.close-dialog {
    float: right;
    cursor: pointer;
}

#divClapping.ShowClapping {
    display: flex !important;
    -webkit-animation: 1s ease 0s normal forwards 1 fadein;
    animation: 1s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    66% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

.wordle-outer {
    display: flex;
    flex-wrap: wrap;
}

.wordle-outer .wordle-inputs {
    min-width: 300px;
    padding-right: 15px;
    padding-left: 15px;
}

.wordle-outer .wordle-inputs>div[style="display: flex;"] {
    flex-wrap: wrap;
    margin-bottom: .5rem !important;
}

.wordle-outer .wordle-actions {
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: .5rem !important;
}

@media (max-width: 992px) {
    .countdown {
        top: 60px;
    }
}

@media (max-width: 767px) {
    .header {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header span {
        font-size: 15px !important;
    }

    .countdown.top {
        top: 58px;
    }
}

@media (max-width: 450px) {
    .QubitImgDesktop {
        display: block;
        width: 100% !important;
        height: auto !important;
        margin: 0 auto;
        max-width: 166px;
    }

    .go-back {
        position: relative;
        justify-content: center;
    }

    .print-heading {
        flex-direction: column;
        align-items: center;
    }

    .print-heading.resul-heading {
        display: block;
        margin: 0 -30px;
    }

    .print-heading .heading-text {
        margin-bottom: 15px;
    }
}

.green.small.button.start-test {
    display: block;
    position: absolute;
    left: calc(50% - 120px);
    border: 4px solid #11872d;
    margin: 40px auto !important;
    font-size: 30px;
    line-height: 32px;
    border-radius: 20px;
    text-align: center;
    padding: 20px;
    text-shadow: 0 1px 1px #444;
    -moz-box-shadow: inset 8px 7px 28px rgba(255, 255, 255, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
    -webkit-box-shadow: inset 8px 7px 28px rgba(255, 255, 255, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
    box-shadow: inset 8px 7px 28px rgba(49, 49, 49, 0.4), inset -2px -2px 2px rgba(0, 0, 0, .4);
}

.w2em {
    width: 2.5em !important;
}

.Scenario-heading {
    color: white;
    font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
    font-size: 16px;
}

.slide-in {
    opacity: 0;
    transform: translateX(-100%);
    animation: slide-in 0.5s ease-in-out forwards;
}

@keyframes slide-in {
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

.slide-down {
    opacity: 0;
    transform: translateY(-100%);
    animation: slide-down 0.5s ease-in-out forwards;
}

@keyframes slide-down {
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}
.axpCont
{
    display: block;
    padding: 16px;
    width: 100%;
    position: relative;
    animation-name: explanationTab;
    animation-duration: 1s;
    overflow: hidden;
}
@keyframes explanationTab {
    0%   { margin-top: -30px;opacity: 0;}
    100%  { margin-top: 0px; opacity: 1;}
  }